<template>
    <answer-panel
    url="/api/subject/randomStPage"
    >

    <template slot="top">
        <div>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>随机练习</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="title">随机练习</div>
    </template>

    </answer-panel>
   
</template>

<script>
import AnswerPanel from "../components/AnswerPanel.vue"
 
export default {
    data() {
        return {
            startTime:'',
            endTime:'',
            type: '单选',
            idx: 0,
            isOver: false,
            radio: null,
            checked: [],
            rightCount: 0,
            errorCount: 0,
            loading: true,
            empty: false,
            total: 0,
            page: 1,
            pageSize: 20,
            cacheMap: {},
            questionList: [],
            currentQuestion: {},
            answerList: [],
            isCollect: false,
            checking: false,
            showFeedbckDialog: false,
            feedbackForm:{
                subjectId:null,
                type:'',
                refer:'',
                imageUrl:[],

            },
            feedbackQuestionTypeOptions:[],
            feedbackBtnLoading:false,
            autoNext: this.$store.state.user.autoNext,
            rememberMode: this.$store.state.user.rememberMode,
            showNote:false,
            noteBtnLoading:false,

            feedbackFormRules:{
                type:[
                { required: true, message: '请选择问题类型', trigger: 'change' },
                ],
                refer:[
                { required: true, message: '请输入问题描述', trigger: 'blur' },
                ],

            }
        }
    },
    components: {
        AnswerPanel,
      
    }

}
</script>
